@primary-light-color: rgb(64, 42, 135);
@primary-dark-color: #bda7e8;

.ticketing__card__item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;

  .type-4 {
    padding-inline: 24px;
    opacity: 0;
    transition: opacity 0.3s ease 0.2s;
  }
  &:hover {
    .type-4 {
      opacity: 1;
    }
  }
}

.ticketing__card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  padding: 24px 0px 12px 0px;
  gap: 12px;
  border-radius: 20px;

  a:hover,
  .ticket_label_text:hover {
    text-decoration: underline;
  }

  .ticketing__card__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    .title_ticketing_widget {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }
  .ticketing__card__body {
    gap: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;

    .anticon > svg {
      width: 60px;
      height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
    &.hovered {
      .anticon > svg {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
      }
    }
    .body_icon_ctn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 100%;
    }
    .body_card_subtitle {
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 700;
      line-height: 14.63px;
      text-align: center;

      width: 100%;

      .body_card_btn {
        font-size: 12px;
        cursor: pointer;
        width: 100%;
        color: #fff;
        height: 24px;
        padding: 4px 8px 4px 8px;
        gap: 0px;
        border-radius: 20px;
      }
    }
  }
  .ticketing__card__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .ticketing__list_details_labels {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 8px;
  }
  .footer_label_details {
    //cursor: pointer;
    display: flex;
    width: 100%;
    overflow: hidden;
    min-height: 96px;
    .ticket_label_row {
      cursor: pointer;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      .ticket_label_text {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0%;
        text-align: center;
        flex-wrap: nowrap;
        margin: 0px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 240px;
      }
      .ticket_label_badge {
        border-radius: 10px;
        background: #bda7e8;
        width: min-content;
        height: 18px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px 0px 8px;

        font-family: Montserrat;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0%;
        text-align: center;
      }
    }
  }
  .not_available_text {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    min-height: 96px;
  }
}

.type-4.dark .rounded-circle-arrow:hover {
  color: #fff !important;
  stroke-opacity: 1;
  stroke: none;
  box-shadow: 0px 0px 10px 0px #402a8733;
}

//styles according theme
.set-ticketing-widget(@primaryColor) {
  .title_ticketing_widget {
    color: @primaryColor;
  }
  .anticon > svg {
    color: @primaryColor;
  }
  .ticket_label_text {
    color: @primaryColor;
  }
  .body_card_subtitle {
    color: @primaryColor;
  }
  .body_card_btn {
    background: @primaryColor;
  }
}

//---light theme
.ticketing__card.light {
  .set-ticketing-widget(@primary-light-color);
  a:hover,
  .ticket_label_text:hover {
    text-decoration-color: rgba(64, 42, 135, 1);
  }
}
//---dark theme
.ticketing__card.dark {
  .set-ticketing-widget(@primary-dark-color);
  a:hover,
  .ticket_label_text:hover {
    text-decoration-color: #bda7e8;
  }
}

//Mobile styles
@media screen and (max-width: 768px) {
  .ant-card-body:has(.ticketing__card) {
    padding: 20px 0px 20px 0px !important;
  }
  .title_ticketing_widget {
    font-size: 18px !important;
  }
  .text-icon {
    min-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ticketing__card {
    padding: 4px;
    gap: 24px;

    .ticketing__card__body {
      margin-bottom: 24px;

      width: 100%;
      .body_icon_ctn {
        height: 100%;
        .anticon > svg {
          width: 120px;
          height: 120px;
          max-width: 120px;
          max-height: 120px;
        }
      }
      .body_card_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 28px;
      }
    }
  }

  .ticketing__card__item {
    .type-4 {
      width: 100%;
      opacity: 1;
    }
  }
}
